import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Les fructificacions són petites, fins a 8 cm d’alçada. Presenten una mena de capell de color obscur quasi negre, format per dos a tres lòbuls disposats en forma de sella de muntar. El peu és cilíndric i presenta un color més clar, des de blanc fins a gris i està profundament surcat en sentit longitudinal. Les espores són el·líptiques, amb una grossa gota lipídica central i altres més petites, de 17-20 x 10-13 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      